import React, { useState } from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { backgroundColor, textColor, } from "../theme";
import fetch from "../fetch";
import { pagesQuery } from "../queries";
import Logo from "../svgr/Logo";
import scrollHandler from "../scrollHandler";
import { colors, fonts, breakpoints, navHts } from "../helpers";

const NavWrap = styled.nav`
  align-items: center;
  background-color: ${props => (props.compress || props.isOpen ? backgroundColor : "transparent")};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: ${navHts.mobileNavHeight};
  padding: 0 20px;
  position: fixed;
  top: 0;
  transition: 0.25s;
  width: 100%;

  z-index: ${props => (props.isOpen ? 12 : 11)};

  ul {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    background-color: ${backgroundColor};
    left: 0;
    right: 0;
    top: ${navHts.mobileNavHeight};
    position: fixed;
    padding: 1rem;
    margin: 0;
    transform: ${props => (props.isOpen ? `translateY(0)`: `translateY(calc(-100% - ${navHts.mobileNavHeight}))`)};
    transition: transform 0.25s;


    li {
      margin-bottom: 20px;
    }
  }

  svg {
    height: auto;
    max-width: 100px;
    width: 100%;
    transition: 0.25s;
  }

  a {
    border-bottom: 1px solid transparent;
    color: ${textColor};
    display: block;
    font-family: ${fonts.sans};
    font-size: 0.7rem;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;

    span {
      display: block;
      transition: all 0.25s;
    }

    &:hover {
      span { transform: translateY(-5px); }
    }

    &.active {
      border-bottom: 1px solid ${colors.red};
    }
  }

  ${breakpoints.md} {
    background-color: ${props => (props.compress ? backgroundColor : "transparent")};
    height: ${props => (props.compress ? navHts.deskNavHeightSm : navHts.deskNavHeight)};
    padding: 0 30px;

    a {
      padding: 0 0.5rem 5px;
    }

    svg {
      max-width: ${props => (props.compress ? "200px" : "300px")};
    }

    ul {
      align-items: flex-end;
      flex-direction: row;
      justify-content: flex-end;
      transform: none;
      position: unset;
      padding: 0;

      li {
        margin-bottom: 0;
      }
    }
  }

  ${breakpoints.lg} {
    padding: 0 ${navHts.mobileNavHeight};
  }
`;

const LogoWrap = styled.div`
  color: ${textColor};
  text-align: left;
  transition: 0.25s;

  a {
    display: block;
    padding: 0;
    text-decoration: none;
  }
`;

const NavBtn = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;
  border-top: 2px solid ${textColor};
  border-bottom: 2px solid ${textColor};
  display: block;
  height: 12px;
  outline: none;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;

  ${breakpoints.md} {
    display: none;
  }
`;


const Nav = () => {
  const { data } =  fetch(pagesQuery);
  const [navOpen,setNavOpen] = useState(false)
  const [scroll, setScroll] = useState({ compress: false })

  function onScroll() {
    let scrollY = typeof window !== "undefined" && window.pageYOffset >= 0 ? window.pageYOffset : null
    setScroll(prev => ({ compress: scrollY > 200 }))
  }

  scrollHandler(onScroll, 500)

  return (
    <NavWrap compress={scroll.compress} isOpen={navOpen}>
      <LogoWrap>
        <Link to="/">
          <Logo />
        </Link>
      </LogoWrap>
      <ul>
        {data ? data.entries.map(page => page.slug !== "home" ? <li key={page.id}>
            <NavLink to={`/${page.slug}`} onClick={() => { setNavOpen(false) }}>
              <span>{page.title}</span>
            </NavLink>
          </li> : null
        ) : null}
        <li>
          <NavLink to="/search" onClick={() => { setNavOpen(false) }}>
            <span>Search</span>
          </NavLink>
        </li>
      </ul>

      <NavBtn onClick={() => { setNavOpen(prev => !prev) }} />
    </NavWrap>
  );
};

export default Nav;
