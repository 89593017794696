import React from "react";
import styled from "styled-components";
import { colors, fonts, breakpoints } from "../helpers";

const SectHeading = styled.h2`
  color: ${({ color }) => (color ? colors[color] : colors["red"])};
  font-family: ${fonts["serif"]};
  font-size: 36px;
  font-weight: 400;
  line-height: 38px;

  ${breakpoints.sm} {
    font-size: 70px;
    line-height: 74px;
    margin: ${({ align }) => (align === "left" ? "0.83em 0 0.5rem" : "0.83em 1.5em")};
    text-align: ${({ align }) => (align ? align : "center")};
  }
`;

const SectionName = styled.h3`
  color: ${({ color }) => (color ? colors[color] : colors["blue"])};
  font-family: ${fonts["sans"]};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;

  ${breakpoints.sm} {
    font-size: 16px;
    text-align: ${({ align }) => (align ? align : "center")};
  }
`;

const SectionHeading = ({ textColor, align, children }) => {
  return <SectHeading align={align} color={textColor}>{children}</SectHeading>;
};

export const Descriptor = ({ textColor, align, children }) => {
  return <SectionName align={align} color={textColor}>{children}</SectionName>;
};

export default SectionHeading;
