import React from "react";
import { Helmet } from "react-helmet";

const Seo = ({ seoData }) => {
  const { title, description, social, advanced } = seoData

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="canonical" href={advanced.canonical} />

      <meta name="description" content={description} />

      {
        // <meta property="fb:app_id" content="{{ fb.handle }}" />
      }
      <meta property="og:url" content={process.env.REACT_APP_API} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={social.facebook.title} />
      <meta property="og:image" content="{{ craft.seo.facebookImage(fb.image) }}" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:description" content={social.twitter.image ? social.twitter.image.url : ""} />
      {
        // <meta property="og:site_name" content="{{ siteName }}" />
      }
      <meta property="og:locale" content="en_US" />

      <meta name="twitter:card" content="summary_large_image" />
      {
        // <meta name="twitter:site" content="{{ tw.handle }}" />
      }
      <meta name="twitter:url" content={process.env.REACT_APP_API} />
      <meta name="twitter:title" content={social.twitter.title} />
      <meta name="twitter:description" content={social.twitter.description} />
      <meta name="twitter:image" content={social.twitter.image ? social.twitter.image.url : ""} />

      <meta name="robots" content={advanced.robots} />

      <link rel="home" href={process.env.REACT_APP_API} />
    </Helmet>
  );
};

export default Seo;
