import React from "react";
import { render, hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import MyThemeProvider from "./ThemeContext";
import { ApolloProvider } from "@apollo/react-hooks";
import createClient from "./createApolloClient";

const client = createClient();
const renderMethod = !!module.hot ? render : hydrate;
renderMethod(
  <BrowserRouter>
    <MyThemeProvider>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </MyThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
