import React from "react";
import ResponsiveImage from "./ResponsiveImage";
import { Debug, fonts, breakpoints } from "../helpers";
import styled from "styled-components";

const ImageBlock = styled.figure`
  display: flex;
  flex-direction: column;
  margin: 0;

  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  & > div {
    &:only-child {
      width: 100%;
    }
    img {
      width: 100%;
    }

    div {
      padding: 0 1rem;
    }
  }

  ${breakpoints.sm} {
    flex-direction: row;
    justify-content: space-between;

    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    & > div {
      width: calc(50% - 10px);
      &:only-child {
        width: 100%;
      }
      img {
        width: 100%;
      }

      div {
        padding: 0;
      }
    }
  }
`;
const ABlock = styled.div`
  position: relative;
`;

const Copy = styled.div`
  padding: 0 1rem;
  ${breakpoints.sm} {
    padding: 0;
  }
`;

const Quote = styled.blockquote`
  font-family: ${fonts["serif"]};
  font-size: 28px;
  line-height: 36px;

  ${breakpoints.sm} {
    font-size: 60px;
    line-height: 80px;
    text-align: center;
  }
`;

const ArticleBlocks = ({ blocks }) => {
  return blocks.map(block => (
    <ArticleBlock key={block.id} block={block} />
  ))
};

const ArticleBlock = ({ block }) => {
  return (
    <ABlock>
      <Debug>{block.typeHandle}</Debug>

      {block.typeHandle === "imageBlock" && (
        <ImageBlock>
          {block.children &&
            block.children.map((child, index) => (
              <ArticleBlock block={child} key={index} />
            ))}
        </ImageBlock>
      )}
      {block.typeHandle === "copyBlock" && (
        <Copy><div dangerouslySetInnerHTML={{ __html: block.copy }} /></Copy>
      )}
      {block.typeHandle === "pullquote" && <Quote>"{block.text}"</Quote>}
      {block.typeHandle === "image" && (
        <>
          <ResponsiveImage imageObject={block.image} />
          <div>{block.caption}</div>
        </>
      )}
    </ABlock>
  );
};

export default ArticleBlocks;
