import React from "react";
import GlobalFonts from "./globalStyles";
import styled, { withTheme } from "styled-components";
import { line, navHts, breakpoints } from "./helpers";

import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import ResourcePage from "./components/ResourcePage";
import ArticlePage from "./components/ArticlePage";
import FooterPage from "./components/FooterPage";
import Page from "./components/Page";
import SearchResults from "./components/SearchResults";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ScrollToTop from "react-router-scroll-top";
import { backgroundColor } from "./theme";

const AppWrap = styled.div`
  background-color: ${backgroundColor};
  padding-top: ${navHts.mobileNavHeight};
  transition: 0.25s;

  &:before {
    ${line}
    height: 140px;
    top: 0;
  }

  ${breakpoints.md} {
    padding-top: ${navHts.deskNavHeightSm};
  }
`;

const App = (props) => {
  return <AppWrap>
    <GlobalFonts />
    <ScrollToTop />
    <Route path="/" exact={true}>
      <Redirect to="/home" />
    </Route>
    <Nav />
    <Switch>
      <Route path={"/resources/:slug"} component={ResourcePage} />
      <Route path={"/news/:slug"} component={ArticlePage} />
      <Route path={"/footer-pages/:slug"} component={FooterPage} />
      <Route path="/search" component={SearchResults} />
      <Route path={"/:slug*"} component={Page} />
    </Switch>
    <Footer />
  </AppWrap>
}

export default withTheme(App);
