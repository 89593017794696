import React from "react";
import Link from "./Link";
import Container from "./Container";
import Section from "./Section";
import styled from "styled-components";
import ResponsiveImage from "./ResponsiveImage";
import SectionHeading, { Descriptor } from "./Headings";
import AccordionItem from "./AccordionItem";
import Button from "./Button";
import LoadMore from "./LoadMore";
import Highlight from "./Highlight";
import NewsTile from "./NewsTile";
import CountyMap from "./Map";
import { colors, breakpoints, fonts, line, Debug } from "../helpers";

const Copy = styled.div`
  max-width: 950px;
  margin: 0 auto;

  p {
    font-size: 20px;
    font-family: ${fonts["sans"]};
    font-weight: 300;
    line-height: 1.3;
    margin: 1.5rem 0;
    &:first-child {
      margin-top: 0;
    }
  }

  .p_small {
    font-size: 16px;
  }

  .heading_1--red {
    color: ${colors.red};
    font-family: ${fonts["sans"]};
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
  }

  .heading_2 {
    text-transform: uppercase;
    font-family: ${fonts["sans"]};
    font-weight: 600;
    font-size: 12px;
  }

  ${breakpoints.md} {
    columns: ${({ columns }) => (columns ? columns : 1)};
    column-gap: 20px;
  }

  ${breakpoints.sm} {
    p {
      font-size: 24px;
      line-height: 36px;
    }

    .p_small {
      font-size: 18px;
      line-height: 30px;
    }

    .heading_2 {
      font-size: 16px;
      margin: 2rem 0;
    }

    .heading_1--red {
      font-size: 16px;
    }
  }
`;

const ButtonBlock = styled.div`
  margin-top: 1rem;
  text-align: center;

  ${breakpoints.sm} {
    margin-top: 3rem;
    text-align: ${({ align }) => align};
  }
`;

const ImageSection = styled.section`
  width: 100%;
  background-color: ${colors["blue"]};
  padding: 0;
  display: flex;
  flex-direction: column;
  img {
    object-fit: cover;
    width: 100%;
    order: 2;
    &:only-child {
      width: 100%;
    }
  }
  ${breakpoints.sm} {
    flex-direction: row;

    img {
      width: 50%;
    }
  }
`;
const ImageCopy = styled.div`
  order: 1;
  background: ${colors["white"]};
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 100px 20px;

    & > div {
      display: flex;
      flex-direction: column;
    }
  }
  ${breakpoints.sm} {
    padding: 100px 0;
    position: relative;
    width: 50%;

    & > div {
      width: 50%;
    }

    &:after {
      ${line}
      left: 100%;
      bottom: 0;
      top: 0;
    }
  }
`;

const Pullquote = styled.div``;
const QuoteText = styled.div`
  color: ${colors["blue"]};
  font-size: 40px;
  text-align: center;

  ${breakpoints.sm} {
    font-size: 60px;
  }
`;

const QuoteAttribution = styled.div`
  font-family: ${fonts["sans"]};
  font-style: italic;
  font-weight: 300;
  text-align right;
  margin-top: 5px;

  &:before {
    content: "-";
    display: inline-block;
    margin-right: 10px;
  }
`;

const PeopleList = styled.ul`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  max-width: 950px;
  margin: 0 auto;
  list-style: none;

  a {
    width: 100;
    text-decoration: none;
  }

  ${breakpoints.sm} {
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 2rem;
  }
`;
const Person = styled.li`
  background-color: ${colors["white"]};
  font-family: ${fonts["sans"]};
  text-align: center;
  padding: 40px 20px;

  img {
    height: auto;
    margin-top: 1rem;
    width: 100%;
  }
`;
const Name = styled.div`
  font-weight: 600;
`;
const JobTitle = styled.div``;

const Steps = styled.div`
  background-color: ${colors["white"]};
  display: grid;
  grid-template-columns: 1fr;
  font-family: ${fonts["sans"]};

  p {
    font-weight: 300;
  }

  ${breakpoints.sm} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 100px 40px;
  position: relative;

  & + div:before {
    content: "";
    height: calc(100% + 40px);
    width: 2px;
    top: 0;
    background-color: ${colors["red"]};
    position: absolute;

    width: calc(100% + 20px);
    height: 2px;
    left: -10px;
  }
  p {
    font-size: 24px;
  }

  ${breakpoints.sm} {
    & + div:before {
      height: calc(100% + 40px);
      width: 2px;
      top: -20px;
      left: 0;
    }
  }
`;
// const Expander = styled.div`
//   width: 100%;
// `;
const StepLabel = styled.span`
  text-transform: uppercase;
  font-weight: 700;
  font-size: 32px;
`;

const ContentBlocks = ({ blocks }) => {
  return (
    <React.Fragment>
      {blocks.length &&
        blocks.map(block => (
          <Block block={block} key={block.id} />
        ))}
    </React.Fragment>
  );
};

const Block = ({ block, bgColor, type }) => {
  return (
    <React.Fragment>
      {block.typeHandle === "sectionBlock" && (
        <Section color={block.backgroundColor}>
          <Container>
            {block.children.length &&
              block.children.map((child, index) => (
                <React.Fragment key={index}>
                  <Debug>{child.typeHandle}</Debug>
                  <Block block={child} bgColor={block.backgroundColor} />
                </React.Fragment>
              ))}
          </Container>
        </Section>
      )}
      {block.typeHandle === "imageSection" && (
        <ImageSection>
          {block.image.length && (
            <ResponsiveImage imageObject={block.image} />
          )}
          {block.children.length && (
            <ImageCopy>
              <div>
                {block.children.map((child, index) => (
                  <React.Fragment key={index}>
                    <Block block={child} type={"split"} />
                  </React.Fragment>
                ))}
              </div>
            </ImageCopy>
          )}
        </ImageSection>
      )}
      {block.typeHandle === "heading" && (
        <div>
          {block.descriptor && (
            <Descriptor
              textColor={bgColor === "softWhite" ? "blue" : "red"}
              align={type === "split" ? "left" : "center"}
            >
              {block.descriptor}
            </Descriptor>
          )}
          {block.heading && (
            <SectionHeading
              textColor={bgColor === "softWhite" ? "red" : "blue"}
              align={type === "split" ? "left" : "center"}
            >
              {block.heading}
            </SectionHeading>
          )}
        </div>
      )}
      {block.typeHandle === "copy" && (
        <Copy columns={block.columns}>
          <div
            dangerouslySetInnerHTML={{
              __html: block.copy
            }}
          />
          {block.children.length ? <LoadMore content={block.children} copy={"Read More"} limit={1} hide={true}>
            {(data) => {
              if (!data) {return "Loading"}
              return data.map(copy => <div
                key={copy.id}
                dangerouslySetInnerHTML={{ __html: copy.copy }}
              />)
            }}
          </LoadMore> : null}
        </Copy>
      )}
      {block.typeHandle === "buttons" && (
        <ButtonBlock align={block.alignment}>
          {block.buttons.map((button, index) => (
            <React.Fragment key={index}>
              <Button
                field={button}
                alignment={button.alignment}
                color={bgColor === "softWhite" ? "blue" : "red"}
              >
                {button.linkField.customText} {button.type}
              </Button>
            </React.Fragment>
          ))}
        </ButtonBlock>
      )}
      {block.typeHandle === "pullquote" && (
        <Pullquote>
          {block.descriptor && (
            <Descriptor textColor={"blue"}>
              {block.descriptor}
            </Descriptor>
          )}
          <QuoteText>{block.text}</QuoteText>
          <QuoteAttribution>{block.attribution}</QuoteAttribution>
        </Pullquote>
      )}
      {block.typeHandle === "people" && (
        <PeopleList>
          {block.peopleList.map((person, index) => (
            <Person key={index}>
              <Name>
                {person.firstName} {person.lastName}
              </Name>
              <JobTitle>{person.jobTitle}</JobTitle>
            </Person>
          ))}
        </PeopleList>
      )}
      {block.typeHandle === "steps" && (
        <Steps>
          {block.stepsBuilder.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.stepLabel}</StepLabel>
              <p>{step.stepDescription}</p>
            </Step>
          ))}
        </Steps>
      )}
      {block.typeHandle === "accordion" && (
        <React.Fragment>
          {block.accordion.map((row, index) => (
            <AccordionItem
              key={index}
              question={row.expanderText}
              answer={row.expandable}
            >
              <div>{row.expanderText}</div>
              <div>{row.expandable}</div>
            </AccordionItem>
          ))}
        </React.Fragment>
      )}
      {block.typeHandle === "organizations" && (
        <PeopleList>
          {block.organizationsList.map((org, index) => (
            <Person key={index}>
              <Link type="external" url={org.organizationUrl} title={`go to ${org.organizationName} site`}>
                <Name>
                  {org.organizationName}
                  <img
                    src={org.logo[0].url}
                    width={org.logo[0].width}
                    height={org.logo[0].height}
                    alt={`logo or headshot of ${org.organizationName}`}
                    />
                </Name>
              </Link>
            </Person>
          ))}
        </PeopleList>
      )}
      {block.typeHandle === "featuredArticle" && (
        <Highlight block={block}></Highlight>
      )}
      {block.typeHandle === "resources" && (
        <PeopleList>
          {block.resources.length ? <LoadMore content={block.resources} copy={"See full list"} limit={3}>
            {(data) => {
              if (!data) {return "Loading"}
              return data.map(resource =>
                <div key={"resources-" + resource.id}>
                  <Link type="internal" url={`/resources/${resource.slug}`}>
                    <NewsTile
                      title={resource.title}
                      head={resource.authoredBy}
                      metaRight={resource.type[0] ? resource.type[0].title : null}
                      metaLeft={resource.publicationDate}
                    />
                  </Link>
                </div>
              )
            }}
          </LoadMore> : null}
        </PeopleList>
      )}
      {block.typeHandle === "map" && (<CountyMap />)}
    </React.Fragment>
  );
};

export default ContentBlocks;
