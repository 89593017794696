import React, { useState } from "react"
import styled from "styled-components";
import { breakpoints, colors } from "../helpers";
import Caret from "../svgr/Caret";

const ViewMoreButton = styled.button`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: ${colors.blue};
  display: flex;
  flex-direction: column;
  font-style: italic;
  font-weight: 300;
  justify-content: center;
  margin: 1rem auto;
  min-width: 200px;
  outline: none;

  svg {
    transform: rotate(90deg);
    transition: all 0.25s;
    * {
      stroke: ${colors.blue};
    }
  }

  &:hover {
    cursor: pointer;

    svg {
      transform: rotate(90deg) translateX(3px);
    }
  }

  ${breakpoints.sm} {
    font-size: 16px;
    grid-column-end: 4;
    grid-column-start: 1;
    margin: 3rem auto;
  }
`;

const LoadMore = ({
  content,
  limit,
  copy,
  hide=false,
  children
}) => {
  const [offset,setOffset] = useState(hide ? 0 : limit)
  let total = content.length

  return content ? <React.Fragment>
    {children(content.slice(0,offset))}
    {offset < total ? <ViewMoreButton onClick={(e)=>{
      setOffset(off=>off+limit)
    }}>
      {copy}
      <Caret />
    </ViewMoreButton>
  : null }
  </React.Fragment> : null
}

export default LoadMore
