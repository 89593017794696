import React from "react";
import styled from "styled-components";
import Link from "./Link";
import { colors, fonts } from "../helpers";

export const Btn = styled.div`
  button, a {
    border: 1px solid ${({ color }) => (color ? colors[color] : colors["blue"])};
    color: ${({ color }) => (color ? colors[color] : colors["blue"])};
    text-align: center;
    font-family: ${fonts.sans};
    margin: 0 auto;
    padding: 19px 40px;
    position: relative;
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.8rem;

    span {
      position: relative;
      transition: color 0.3s;
      z-index: 1;
    }

    &:before {
      background-color: ${({ color }) => (color ? colors[color] : colors["blue"])};
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      top: 0;
      transition: all 0.3s;
      width: 0;
      z-index: 0;
    }

    &:hover {
      color: ${({ color }) => (color === "white" ? colors["blue"] : colors["white"])};

      &:before {
        width: 100%;
      }
    }
  }
`;

const Button = ({ field, color }) => {
  let url = field.linkField.type === "entry" && field.linkField.element ? field.linkField.element.uri : field.linkField.url
  return (
    <Btn color={color}>
      {field.linkField && field.linkField.url && (
        <Link
          url={url}
          type={field.linkField.type === "entry" ? "internal" : "external"}
          title={field.linkField.title}
        >
          <span>{field.linkField.text}</span>
        </Link>
      )}
    </Btn>
  );
};

export default Button;
