import React from "react";
import styled from "styled-components";
import Link from "./Link";
import { recentNews } from "queries";
import fetch from "fetch";
import { Descriptor } from "./Headings";
import ResponsiveImage from "./ResponsiveImage";
import Button from "./Button";
import { ArticleMeta } from "./ArticleHead";
import { colors, fonts, breakpoints } from "helpers";

const Card = styled.div`
  padding-top: 2rem;

  a {
    text-decoration: none;
  }

  > a {
    display: block;
    margin-top: 2rem;
  }

  > div {
    margin-top: 2rem;
  }

  ${breakpoints.sm} {
    padding: 50px 2rem;

    > div {
      text-align: center;
    }
  }
`;

const Tile = styled.div`
  background-color: ${colors.white};
  border: 2px solid ${colors.red};
  color: ${colors.blue};
  display: grid;
  grid-template-rows: 1fr 1fr;

  h3, h4, p {
    text-align: left;
  }

  h4 {
    font-family: ${fonts["serif"]};
    font-size: 28px;
    font-weight: 300;
    margin-top: 0;
  }

  figure {
    margin: 0;
    position: relative;
  }

  img {
    height: 100%;
    left: 50%;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    opacity: 1;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.25s;
    width: 100%;
  }

  > div {
    font-family: ${fonts["sans"]};
    font-weight: 300;
    padding: 1rem;
  }

  aside {
    margin-bottom: 0;
  }

  &:hover img {
    opacity: 0.5;
  }

  ${breakpoints.sm} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    figure {

    }

    img {
      height: 100%;
      left: 50%;
      max-height: 100%;
      max-width: 100%;
      object-fit: cover;
      opacity: 1;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    > div {
      padding: 40px;

      p { margin-bottom: 2rem; }
    }

    aside {
      margin-bottom: 2rem;
    }
  }
`;

const Highlight = ({ block }) => {
  const latest = fetch(recentNews);
  let article = block.useLatest ? (latest.data && latest.data.entries[0]) : block.article[0];
  return article ? (
    <Card>
      <Descriptor textColor="blue">{block.descriptor}</Descriptor>
      <Link type="internal" url={`/news/${article.slug}`} title={`${article.title} page`}>
        <NewsTile article={article} />
      </Link>

      <Button field={{ linkField: { url: "/news", text: "News", type: "entry", element: { uri: "news" } }}} color={"red"} />
    </Card>
  ) : null;
};

const NewsTile = ({ article }) => {
  const { title, publicationOutlet, publicationDate, featuredImage, summaryExcerpt, authoredBy } = article;

  return <Tile>
    <figure>
      { featuredImage ? <ResponsiveImage imageObject={featuredImage} /> : null }
    </figure>
    <div className="content">
      <header>
        <Descriptor textColor="red">{publicationOutlet}</Descriptor>
        <h4>{title}</h4>
      </header>
      <div dangerouslySetInnerHTML={{ __html: summaryExcerpt }} />
      <ArticleMeta author={authoredBy} date={publicationDate} />
    </div>
  </Tile>
}

export default Highlight;
