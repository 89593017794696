import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { useTheme } from "../ThemeContext";
import { Link } from "react-router-dom";
import ArticleContainer from "./ArticleContainer";
import { breakpoints, colors, fonts } from "helpers";

const ErrorPage = styled.section`
  text-align: center;

  h1 {
    font-size: 80px;
    color: ${colors.red};
    font-family: ${fonts["sans"]};
  }

  p, a {
    font-weight: 600;
  }

  a {
    border-bottom-color: ${colors.red};
  }

  ${breakpoints.md} {
    h1 {
      font-size: 200px;
      line-height: 200px;
      margin: 2rem 0;
    }
  }
`;

const NotFoundPage = ({ article, theme }) => {
  const themeToggle = useTheme();

  useEffect(() => {
    if (theme.mode === "dark") {
      themeToggle.toggle("light");
    }
  });

  return (
    <ArticleContainer>
      <ErrorPage>
        <h1>404</h1>
        <p>
          This page does not exist. Click here <Link to="/home">Click here</Link> to return the NYAPSA site.
        </p>
      </ErrorPage>
    </ArticleContainer>
  );
};

export default withTheme(NotFoundPage);
