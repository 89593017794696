import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { useTheme } from "../ThemeContext";
import { NavLink } from "react-router-dom";
import { Btn } from "./Button";
import ArticleContainer from "./ArticleContainer";
import ArticleHead from "./ArticleHead";
import NotFoundPage from "./NotFoundPage";
import { PageLoading } from "./Page";
import fetch from "../fetch";
import { resourcesQuery } from "../queries";
import { breakpoints, downloadEvent } from "../helpers";

const Links = styled.div`
  display: block;
  padding: 30px 1rem;

  ${breakpoints.sm} {
    padding: 0;
  }

  a.download-link {
    margin-bottom: 30px;
  }
`;

const ResourcePage = ({ match, theme }) => {
  const { params } = match;
  const { data, loading } = fetch(resourcesQuery);
  const themeToggle = useTheme();

  useEffect(() => {
    if (theme.mode !== "light") {
      themeToggle.toggle("light");
    }
  });

  let resource = data ? data.entries.filter(art => art.slug === params.slug) : null;

  if (loading) {
    return <PageLoading />
  }

  return resource ? <Page resource={resource[0]} />  : <NotFoundPage />
};

const Page = ({ resource }) => {
  let type = resource.type[0] ? resource.type[0].title : null
  // let baseURl = process.env.DEFAULT_SITE_URL ? process.env.DEFAULT_SITE_URL : "http://localhost:3000"
  
  return <ArticleContainer>
    <ArticleHead article={resource} type={type} />

    <Links>
      {resource.file ? resource.file.map(file =>
        <Btn key={file.id} color={"red"}>
          <a 
            href={file.url} 
            title={`Download ${file.filename}`}
            className="download-link"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => downloadEvent(file.filename) }
            download
          ><span>{`Download Resource`}</span></a>
        </Btn>)
      : null}

      {/*<ArticleBlocks blocks={resource.resourceBlocks} /> */}
      <NavLink to={`/library`}>Back</NavLink>
    </Links>
  </ArticleContainer>;
}

export default withTheme(ResourcePage);
