import { createGlobalStyle } from "styled-components";

// import HeldaneWoff from "./fonts/heldane-display-web-regular.woff";
// import HeldaneWoff2 from "./fonts/heldane-display-web-regular.woff2";
// import HeldaneEot from "./fonts/heldane-display-web-regular.eot";

import { colors } from "./helpers";

export default createGlobalStyle`
    @font-face {
        font-family: 'Heldane';
        src: local('Heldane'), local('Heldane'),
        url("./fonts/heldane-display-web-regular.woff2") format('woff2'),
        url("./fonts/heldane-display-web-regular.woff") format('woff'),
        url("./fonts/heldane-display-web-regular.eot") format('eot');
        font-weight: 300;
        font-style: normal;
    }

    html{
        box-sizing: border-box;
        font-size: 20px;
    }

    * {
      box-sizing: inherit;
    }

    body {
        color: ${colors.blue};
        margin: 0;
    }

    a {
        color: ${colors.blue};
    }

    *::selection {
        background-color: ${colors.red};
        color: ${colors.white};
    }

    /* *:not(body) {
        transition: all .5s ease;
    } */
`;
