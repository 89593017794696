import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { useTheme } from "../ThemeContext";

import NotFoundPage from "./NotFoundPage";
import SectionHeading from "./Headings";
import { PageLoading, ErrorPage } from "./Page";
import ArticleContainer from "./ArticleContainer";
import fetch from "../fetch";
import { fonts, breakpoints } from "../helpers";
import { footerPageQuery } from "../queries";

const Wrapper = styled.div`
  padding: 30px 0 1rem;
  font-family: ${fonts["sans"]};

  h2 {
    font-weight: 400;
  }

  .subhead {
    font-weight: 300;
    margin: 3rem 0;
  }

  ${breakpoints.md} {
    padding: 100px 0 1rem;
  }
`;

const FooterPage = ({ match, theme }) => {
  const { params } = match
  const { loading, error, data } = fetch(footerPageQuery, { slug: params.slug });
  const themeToggle = useTheme();

  useEffect(() => {
    if (theme.mode !== "light") {
      themeToggle.toggle("light");
    }
  });

  if (loading) {
    return <PageLoading />;
  }
  if (error) {
    return <ErrorPage />;
  }

  if (data && !data.entries) {
    console.log("inside Not Found",data)
    return <NotFoundPage />;
  }

  if (loading === false && data === undefined && error === undefined) {
    return <ErrorPage />;
  }

  return <PageContent page={data.entries[0]} />;
}

const PageContent = ({ page }) => {
  return <ArticleContainer>
    <Wrapper>
      <SectionHeading color={"red"} align={"left"}>
        {page.title}
      </SectionHeading>

      <h2 className="subhead">{page.heading}</h2>
      <div dangerouslySetInnerHTML={{ __html: page.copy }} />
    </Wrapper>
  </ArticleContainer>
}

export default withTheme(FooterPage);
