const heading = `
            ...on contentBlocks_heading_BlockType{
              id
              typeHandle
              heading
              descriptor
            }`;
const copy = `
            ...on contentBlocks_copy_BlockType{
              typeHandle
              copy
              columns
              children {
                ...on contentBlocks_more_BlockType {
                  id
                  copy
                }
              }
            }`;

const button = `
buttons{
  ...on buttons_linkButton_BlockType{
    id
    linkField {
      ariaLabel
      customText
      target
      text
      title
      type
      url
      element {
        id
        uri
        title
      }
    }
  }
}`

const buttons = `
...on contentBlocks_buttons_BlockType{
  typeHandle
  alignment
  ${button}
}`;

const image = `
  id
  url
  ...on images_Asset{
    optimizedBanner{
      srcset
      placeholderBox
    }
  }
`;

const bannerImage = `
  image{
    ${image}
  }
`;

const article = `
  id
  title
  slug
  summaryExcerpt
  publicationOutlet
  publicationDate
  type: articleType {
    id
    title
  }
  featuredImage {
    ${image}
  }
  authoredBy
`;

const resource = `
  id
  title
  slug
  uri
  authoredBy
  publicationDate
  summaryExcerpt
  type: resourceType {
    id
    title
  }
  file {
    id
    uri
    url
    filename
  }
`;

export const mapData = `{
  entries(section: "countyData") {
    ...on countyData_countyData_Entry {
      id
      title
      slug
      agency
      website
    }
  }
}`;

export const recentNews = `{
  entries(section: "articles", orderBy: "postDate", limit: 1) {
    ...on articles_article_Entry{
      ${article}
    }
  }
}`

export const resourcesQuery = `{
  entries(section:"resources"){
    ...on resources_resources_Entry{
      ${resource}
    }
  }
}`;
export const newsQuery = `{
  entries(section:"articles"){
    ...on articles_article_Entry{
      ${article}
      articleBlocks{
        ...on articleBlocks_imageBlock_BlockType{
          id
          typeHandle
          children{
            ...on articleBlocks_image_BlockType{
              typeHandle
              ${bannerImage}
              caption
            }
          }
        }
        ...on articleBlocks_pullquote_BlockType{
          id
          typeHandle
          text
          attribution
        }
        ...on articleBlocks_copyBlock_BlockType{
          id
          typeHandle
          copy
        }
      }
    }
  }
}`;

export const pageSeo = `
pageSeo {
  title
  description
  keywords {
    keyword
    rating
  }
  social {
    twitter {
      title
      image {
        url
      }
      description
    }
    facebook {
      title
      image {
        url
      }
      description
    }
  }
  advanced {
    canonical
    robots
  }
}`;

export const pagesQuery = `{
  entries(section:"pages"){
    id
    title
    slug
  }
}`;

export const pageQuery = `($slug: [String]) {
  entries(section: "pages", slug: $slug) {
    ...on pages_pages_Entry{
      id
      title
      slug
      ${pageSeo}
      introductionBlock{
        ...on introductionBlock_introduction_BlockType{
          introductionStatement
          ${button}
        }
      }
      contentBlocks{
        ...on contentBlocks_imageSection_BlockType{
          id
          typeHandle
          ${bannerImage}
          children{
            ${heading}
            ${copy}
            ${buttons}
          }
        }
        ...on contentBlocks_sectionBlock_BlockType{
          id
          typeHandle
          backgroundColor
          children{
            id
            typeHandle
            ${heading}
            ${copy}
            ${buttons}
            ...on contentBlocks_featuredArticle_BlockType{
              id
              typeHandle
              descriptor
              useLatest
              article {
                ...on articles_article_Entry {
                  ${article}
                }
              }
            }
            ...on contentBlocks_pullquote_BlockType{
              id
              typeHandle
              descriptor
              text
              attribution
            }
            ...on contentBlocks_accordion_BlockType{
              id
              typeHandle
              accordion{
                ...on accordion_BlockType{
                  id
                  expanderText
                  expandable
                }
              }
            }
            ...on contentBlocks_people_BlockType{
              id
              typeHandle
              peopleList{
                ...on peopleList_BlockType{
                  id
                  firstName
                  lastName
                  jobTitle
                }
              }
            }
            ...on contentBlocks_steps_BlockType{
              id
              typeHandle
              stepsBuilder{
                ...on stepsBuilder_BlockType{
                  id
                  stepLabel
                  stepDescription
                }
              }
            }
            ...on contentBlocks_organizations_BlockType{
              id
              typeHandle
              organizationsList{
                ...on organizationsList_BlockType{
                  id
                  organizationName
                  organizationUrl
                  logo {
                    width
                    height
                    url @transform (width: 600, immediately: true)
                  }
                }
              }
            }
            ...on contentBlocks_resources_BlockType {
              typeHandle
              resources {
                ...on resources_resources_Entry{
                  ${resource}
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export const footerPageQuery = `($slug: [String]) {
  entries(section: "footerPages", slug: $slug) {
    id
    title
    uri
    ...on footerPages_footerPages_Entry {
      heading
      copy
    }
  }
}`

export const globalSeo = `{
  globalSet(handle: "globalSeo") {
    ...on globalSeo_GlobalSet {
      ${bannerImage}
    }
  }

  globalSet (handle: "footer") {
    ...on footer_GlobalSet {
      footerFields {
        ...on footerFields_BlockType {
          socialMedia {
            ...on socialMedia_twitter_BlockType {
              twitterHandle
            }
            ...on socialMedia_facebook_BlockType {
              facebookId
            }
          }
        }
      }
    }
  }
}`;

export const footerQuery = `{
  globalSet (handle: "footer") {
    ...on footer_GlobalSet {
      footerFields {
        ...on footerFields_BlockType {
          address
          contactEmail
          phoneNumber
          faxNumber
          copyrightText
          socialMedia {
            ...on socialMedia_twitter_BlockType {
              id
              typeHandle
              twitterHandle
            }
            ...on socialMedia_facebook_BlockType {
              id
              typeHandle
              facebookId
            }
            ...on socialMedia_youtube_BlockType {
              id
              typeHandle
              youtubePage
            }
            ...on socialMedia_linkedIn_BlockType {
              id
              typeHandle
              linkedinId
            }
          }
        }
      }
    }
  }
  links: entries(section: "footerPages") {
    id
    title
    uri
  }
}`;

export const Rand = `{
  entries(section:"pages", orderBy:"RAND(${Math.random(100)}) desc"){
    id
    title
    ...on news_news_Entry{
      id
      title
    }
  }
}`;

export const searchQuery = `query($param:String) {
  entries(section: ["resources"], search: $param, orderBy: "score") {
    ...on resources_resources_Entry{
      ${resource}
    }
  }
}`;

