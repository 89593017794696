import * as React from "react";

function SvgTwitter(props) {
  return (
    <svg viewBox="0 0 36 36" {...props}>
      <path
        d="M18 0C8.1 0 0 8.1 0 18s8.1 18 18 18 18-8.1 18-18S27.9 0 18 0zm6.5 14.5v.5c0 4.7-3.5 10-10 10-2 0-3.9-.4-5.4-1.4.3 0 .6.1.8.1 1.7 0 3.2-.7 4.4-1.7-1.5 0-2.8-1.1-3.3-2.5.2 0 .4.1.7.1.3 0 .6 0 .9-.1-1.6-.3-2.8-1.8-2.8-3.5.5.3 1 .4 1.6.4-1-.6-1.6-1.7-1.6-2.9 0-.7.2-1.2.5-1.7 1.8 2.1 4.3 3.5 7.3 3.6-.1-.3-.1-.5-.1-.8 0-2 1.6-3.5 3.5-3.5 1 0 1.9.4 2.6 1.1.8-.2 1.6-.5 2.2-.9-.3.8-.8 1.5-1.6 2 .7-.1 1.4-.3 2-.6-.5.7-1.1 1.3-1.7 1.8z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#f7f5f3"
      />
    </svg>
  );
}

export default SvgTwitter;
