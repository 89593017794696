import React from "react";
import { Link as InternalLink } from "react-router-dom";
import styled from "styled-components";
import { fonts, colors, breakpoints, size } from "../helpers";
import Logo from "../svgr/Logo";
import { textColor } from "../theme";
import fetch from "../fetch";
import { footerQuery } from "../queries";
import Link from "./Link";
import SocialLink from "./SocialLink";
import NewsletterSignup from "./NewsletterSignup";

const LogoWrap = styled.div`
  color: ${textColor};
  text-align: left;
  background-color: ${colors.softBlue};
  transition: 0.25s;
  margin-bottom: 20px;
  width: 100%;

  svg {
    max-width: 150px;
    height: auto;
    width: 100%;
    * { fill: ${colors.white}; }
  }
`;

const FooterWrap = styled.footer`
  background-color: ${colors.softBlue};
  bottom: 0;
  color: ${colors.white};
  font-family: ${fonts.sans};
  font-size: 18px;
  font-weight: 300;
  left: 0;
  padding: 20px;
  position: relative;
  right: 0;
  white-space: break-spaces;

  a, p {
    line-height: 30px;
    margin: 0;
  }

  a {
    color: ${colors.white};
    text-decoration: none;
  }

  small {
    display: block;
    font-style: italic;
    margin-top: 20px;
    width: 100%;
  }

  > div {
    max-width: ${size.lg};
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }

  ${breakpoints.md} {
    padding: 40px 40px 20px;

    small {
      margin-top: 40px;
      text-align: center;
    }
  }
`;

const ContactWrap = styled.div`
  margin-bottom: 10px;
  width: 100%;

  > div {
    margin-bottom: 20px;
  }

  ${breakpoints.md} {
    display: flex;
    width: 66%;

    > div {
      margin-bottom: 0;
      width: 50%;
    }
  }
`;

const SocialWrap = styled.div`
  width: 100%;

  ul {
    list-style: none;
    display: flex;
    margin: 0 0 10px;
    padding: 0;
  }

  ${breakpoints.md} {
    width: 33%;

    ul {
      margin: 0;
    }
  }

  li {
    margin-right: 25px;
  }

  a {
    display: block;
    svg {
      transition: transform 0.25s;
    }

    &:hover svg {
      transform: translateY(-5px);
    }
  }

  svg {
    height: 26px;
    width: 26px;
  }
`;

const Footer = () => {
  const { data } = fetch(footerQuery);

  return (
    <>
      { data ? <NewsletterSignup embed={data.globalSet} /> : null }
      <FooterWrap>
        <div>
          <LogoWrap>
            <Logo />
          </LogoWrap>
          {data && (
            <FooterContent data={data} />
          )}
        </div>
      </FooterWrap>
    </>
  );
};

const FooterContent = ({ data }) => {
  const { address, contactEmail, phoneNumber, faxNumber, copyrightText, socialMedia } = data.globalSet.footerFields[0];

  return <>
    <ContactWrap>
      {address && (<div>
        <p>Address:</p>
        <p>{address}</p>
      </div>)}
      {(contactEmail || phoneNumber) && (<div>
        <p>Contact:</p>
        <p>{phoneNumber}</p>
        <p>{faxNumber}</p>
        <Link type="external" url={`mailto:${contactEmail}`} title={`send email to ${contactEmail}`}>
          {contactEmail}
        </Link>
      </div>)}
    </ContactWrap>
    <SocialWrap>
      {socialMedia && (<ul>
        {socialMedia.map(soc => <li key={soc.id}>
          <SocialLink type={soc.typeHandle} data={soc.handle} />
        </li>
        )}
      </ul>)}
      {data.links.map(link => <InternalLink key={link.id} to={`/${link.uri}`}>{link.title}</InternalLink>)}
    </SocialWrap>
    {copyrightText && <small>{copyrightText}</small>}
  </>
};

export default Footer;
