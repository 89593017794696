import React, { useState } from "react"
import styled from "styled-components";
import { Link } from "react-router-dom";
import Container from "./Container";
import Section from "./Section";
import LoadMore from "./LoadMore";
import NewsTile from "./NewsTile";
import { Btn } from "./Button";
import { breakpoints, colors } from "../helpers";
import fetch from "../fetch";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  a {
    width: 100;
    text-decoration: none;
  }

  ${breakpoints.sm} {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const FilterList = styled.div`
  margin: 2rem auto;
  max-width: 1200px;
  padding: 0 20px;

  button {
    background-color: transparent;
    display: block;
    outline: none;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: ${colors["blue"]};
      color: ${colors["white"]};
    }
  }

  ${breakpoints.sm} {
    align-items: center;
    display: flex;
    justify-content: center;

    button {
      width: auto;
    }

    > div {
      margin-right: 20px;
    }
  }
`;

const Filters = ({ entries, setFilter, filter }) => {
  let filters = entries.map(entry => entry.type[0]).filter((v,i,a) =>
    a.findIndex(t => (JSON.stringify(t) === JSON.stringify(v))) === i
  )

  return filters.length > 1 ? <FilterList>
    <Btn>
      <button onClick={() => {
        setFilter(null)
      }} className={!filter ? "active" : ""}>
        <span>{"All"}</span>
      </button>
    </Btn>
    {filters.map(filt => {
      return filt && filt.id ? <Btn key={filt.id}>
        <button onClick={() => {
          setFilter(filt.id)
        }} className={filter === filt.id ? "active" : ""}>
          <span>{filt.title}</span>
        </button>
      </Btn> : null
    })}
  </FilterList> : null
}

const Library = ({ query, type }) => {
  const { data } = fetch(query);
  const [filter, setFilter] = useState(null);

  return <Section>
    {data ? <Filters
      entries={data.entries}
      setFilter={setFilter}
      filter={filter}
      />
    : null}
    <Container>
      <Grid>
        {data ? <LoadMore content={data.entries} copy={"See More"} limit={12}>
          {(data) => {
            if (!data) {return "Loading"}
            return data.map(resource => {
              let resourceType = resource.type[0] ? resource.type[0].id : null
              return (!filter || filter === resourceType) ? <div key={"resources-" + resource.id}>
                <Link to={`/${type}/${resource.slug}`}>
                  <LinkTile entry={resource} type={type} />
                </Link>
              </div> : null
            })
          }}
        </LoadMore>
        : null}
      </Grid>
    </Container>
  </Section>
}

const LinkTile = ({entry, type}) => {
  return type === "resources" ? <NewsTile
    title={entry.title}
    head={entry.authoredBy}
    metaRight={entry.type[0] ? entry.type[0].title : null}
    metaLeft={entry.publicationDate}
  /> : <NewsTile
    title={entry.title}
    head={entry.publicationOutlet}
    metaRight={entry.type[0] ? entry.type[0].title : null}
    metaLeft={entry.publicationDate}
  />
}

export default Library
