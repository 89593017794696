import React from "react";
import styled from "styled-components";
import { fonts, colors, breakpoints } from "../helpers";

const Contained = styled.article`
  font-family: ${fonts["sans"]};
  margin: 0 auto;
  max-width: 1200px;
  padding: 30px 0;
  width: 100%;

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;

    ${breakpoints.sm} {
      font-size: 20px;
      line-height: 26px;
    }
  }

  ${breakpoints.sm} {
    padding: 30px;
  }


  > a {
    border-bottom: 1px solid ${colors.blue};
    color: ${colors.blue};
    display: inline-block;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.25s;

    &:hover {
      border-bottom-color: ${colors.red};
      color: ${colors.red};
    }
  }
`;


const ArticleContainer = ({ children }) => {
  return <Contained>{children}</Contained>;
};

export default ArticleContainer;
