import React from "react"
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

export default ({type, url, title, children}) => {
  return type === "external" ? 
    <ReactGA.OutboundLink
      to={url}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
      eventLabel={url}
      >
      {children}
    </ReactGA.OutboundLink>
    : <Link to={url} title={title}>{children}</Link>
}