import React from "react";

export default ({ imageObject, noCrop = false }) => {
  let optimizedImages = imageObject[0].optimizedBanner;
  if (noCrop) {
    optimizedImages = imageObject[0].optimizedBanner;
  }
  const srcSet = optimizedImages.srcset;

  return (
    <img
      src={optimizedImages.placeholderBox}
      srcSet={srcSet}
      // data-src={imageObject.url}
      // data-srcset={srcSet}
      className={"ResponsiveImage lazyload"}
      alt=""
    />
  );
};
