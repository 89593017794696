// src/theme.jsx
import theme from "styled-theming";
import { colors } from "./helpers";

export const backgroundColor = theme("mode", {
  light: colors.softWhite,
  dark: colors.blue
});
export const textColor = theme("mode", {
  light: colors.blue,
  dark: colors.softWhite
});


// export const buttonBackgroundColor = theme("mode", {
//   light: "#222",
//   dark: "#eee",
// });
// export const buttonBackgroundColor = theme("mode", {
//   light: "#222",
//   dark: "#eee",
// });
// export const buttonTextColor = theme("mode", {
//   light: "white",
//   dark: "black",
// });
