import React, { useEffect } from "react";
import { withTheme } from "styled-components";
import { useTheme } from "../ThemeContext";
import { Link } from "react-router-dom";
import ArticleContainer from "./ArticleContainer";
import NotFoundPage from "./NotFoundPage";
import ArticleBlocks from "./ArticleBlocks";
import ArticleHead from "./ArticleHead";
import { PageLoading } from "./Page";
import fetch from "../fetch";
import { newsQuery } from "../queries";

const ArticlePage = ({ match, theme }) => {
  const { params } = match;
  const { data, loading } = fetch(newsQuery);
  const themeToggle = useTheme();

  useEffect(() => {
    if (theme.mode !== "light") {
      themeToggle.toggle("light");
    }
  });

  let article = data ? data.entries.filter(art => art.slug === params.slug) : null;

  if (loading) {
    return <PageLoading />
  }

  return article ? <Article article={article[0]} /> : <NotFoundPage />
};

const Article = ({ article }) => {
  let type = article.type[0] ? article.type[0].title : "Blog Post"

  return <ArticleContainer>
    <ArticleHead article={article} type={type} />
    <ArticleBlocks blocks={article.articleBlocks} />
    <Link to={`/news`}>Back</Link>
  </ArticleContainer>
}

export default withTheme(ArticlePage);
