import * as React from "react";

function SvgFacebook(props) {
  return (
    <svg viewBox="0 0 36 36" {...props}>
      <path
        d="M18 0C8.1 0 0 8.1 0 18s8.1 18 18 18 18-8.1 18-18S27.9 0 18 0zm5 12.2h-2.5c-.4 0-.7.5-.7 1.1v2.2H23v3.2h-3.2v7.5h-3.2v-7.5H14v-3.2h2.5v-2.2c0-2.4 1.8-4.3 3.9-4.3H23v3.2z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#f7f5f3"
      />
    </svg>
  );
}

export default SvgFacebook;
