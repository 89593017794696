import React, { useEffect } from "react";
import styled, { withTheme } from "styled-components";
import { line, colors, fonts, breakpoints } from "../helpers";
import { useTheme } from "../ThemeContext";
import fetch from "../fetch";
import {
  pageQuery,
  resourcesQuery,
  newsQuery
} from "../queries";

// Components
import Button from "./Button";
import Container from "./Container";
import ContentBlocks from "./ContentBlocks";
import NotFoundPage from "./NotFoundPage";
import Library from "./Library";

import Seo from "./Seo";

const Intro = styled.section`
  color: white;
  padding: 150px 0;
  position: relative;
  max-width: 840px;
  margin: 0 auto;
  text-align: center;

  h1 {
    font-size: 40px;
    font-family: "Heldane";
    font-weight: 400;

    ${breakpoints.sm} {
      font-size: 60px;
    }
  }

  a {
    margin-top: 2rem;
  }

  &:before {
    ${line}
    height: 40px;
    top: 0;
  }
  &:after {
    ${line}
    height: 40px;
    bottom: 0;
  }
`;

const Loading = styled.section`
  color: ${colors.white};
  font-family: ${fonts["sans"]};
  font-weight: 300;
  text-align: center;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Page = ({ match, theme }) => {
  const { params } = match
  const { loading, error, data } = fetch(pageQuery, { slug: params.slug });
  const themeToggle = useTheme();

  useEffect(() => {
    if (theme.mode !== "dark") {
      themeToggle.toggle("dark");
    }
  });

  if (loading) {
    return <PageLoading />;
  }
  if (error) {
    return <ErrorPage />;
  }

  if (data && !data.entries) {
    console.log("inside Not Found",data)
    return <NotFoundPage />;
  }

  if (loading === false && data === undefined && error === undefined) {
    return <ErrorPage />;
  }

  return <PageContent page={data.entries[0]} theme={theme.mode} />;
}

export const ErrorPage = () => {
  return <Loading>
    <h1>500</h1>
  </Loading>;
}

export const PageLoading = () => {
  return <Loading>
    <h2>Loading</h2>
  </Loading>;
}

const PageContent = ({ page, theme }) => {
  let introBlock = page ? page.introductionBlock[0] : null

  return page ? <>
    <Seo seoData={page.pageSeo} />
    {!!introBlock &&
      <Intro>
        <Container>
          <h1>
            {introBlock.introductionStatement}
          </h1>
          {introBlock.buttons.length > 0 && introBlock.buttons.map(button =>
            <Button
              key={button.id}
              field={button}
              alignment={button.alignment}
              color={theme === "dark" ? "white" : "blue"}
            >
              {button.linkField.customText} {button.type}
            </Button>)}
        </Container>
      </Intro>
    }
    {page.contentBlocks.length &&
      <ContentBlocks blocks={page.contentBlocks} />
    }
    {page.slug === "library" ? <Library query={resourcesQuery} type={"resources"} /> : null}
    {page.slug === "news" ? <Library query={newsQuery} type={"news"} /> : null}
  </> : null;
}

export default withTheme(Page);
