import React, {
  useState,
  useContext,
  createContext
} from "react";
import { ThemeProvider } from "styled-components";
// import {
//   backgroundColor,
//   textColor
// } from "./theme";

const ThemeToggleContext = createContext();

export const useTheme = () => useContext(ThemeToggleContext);

const MyThemeProvider = ({ children }) => {
  const [themeState, setThemeState] = useState({ mode: "" });
  // const Wrapper = styled.div`
  //   background-color: ${backgroundColor};
  // `;

  const toggle = (mode) => {
    console.log("TOGGLE", themeState.mode);

    setThemeState({ mode: mode });
  };
  return (
    <ThemeToggleContext.Provider value={{ toggle: toggle }}>
      <ThemeProvider theme={{ mode: themeState.mode }}>
        {children}
      </ThemeProvider>
    </ThemeToggleContext.Provider>
  );
};

export default MyThemeProvider;
