import * as React from "react";

function SvgCaret(props) {
  return (
    <svg width={16} height={28} {...props}>
      <path
        d="M1 1l13 13L1 27"
        stroke="currentColor"
        strokeWidth={2.52}
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default SvgCaret;
