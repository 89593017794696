import React from "react";
import styled from "styled-components";
import Moment from "react-moment";
import { fonts, colors, breakpoints, line } from "../helpers";

const Head = styled.header`
  font-family: ${fonts["sans"]};
  padding: 30px 1rem;

  ${breakpoints.sm} {
    padding: 30px 0;
  }

  h1 {
    font-weight: 600;
    margin: 1rem 0 0.5rem;
  }

  h1 + h3 {
    margin-top: 0;

    p {
      margin-top: 0;
    }
  }

  h3 {
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;

    ${breakpoints.sm} {
      font-size: 24px;
      line-height: 32px;
    }
  }

  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;

    ${breakpoints.sm} {
      font-size: 20px;
      line-height: 26px;
    }
  }

  a {
    border-bottom: 1px solid ${colors.blue};
    color: ${colors.blue};
    display: inline-block;
    font-weight: 300;
    text-decoration: none;
    transition: all 0.25s;

    &:hover {
      border-bottom-color: ${colors.red};
      color: ${colors.red};
    }
  }
`;

const Label = styled.h2`
  color: ${colors.red};
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;

  ${breakpoints.sm} {
    font-size: 16px;
  }
`;

const Meta = styled.aside`
  display: flex;
  margin-bottom: 2rem;
  font-size: 10px;
  line-height: 12px;

  ${breakpoints.sm} {
    font-size: 14px;
    line-height: 18px;
  }

  > div span {
    display: block;
  }

  > div:first-of-type {
    font-weight: 600;
    margin-right: 10px;
    padding-right: 10px;
    position: relative;

    &:after {
      ${line}
      bottom: 0;
      right: 0;
      left: unset;
      top: 0;
      width: 1px;
      z-index: 1;
    }
  }
`;

export const ArticleMeta = ({ author, date }) => (
  <Meta>
    <div>
      {author ? <span>Author</span> : null}
      {date ? <span>Published</span> : null}
    </div>
    <div>
      {author ? <span>{author}</span> : null}
      {date ? <Moment format="MMM D, YYYY">{date}</Moment> : null}
    </div>
  </Meta>
);

const ArticleHead = ({ article, type }) => {
  return (
    <Head>
      <Label>{type}</Label>
      <h1>{article.title}</h1>
      <h3
        dangerouslySetInnerHTML={{
          __html: article.summaryExcerpt
        }}
      ></h3>
      <ArticleMeta author={article.authoredBy} date={article.publicationDate} />
    </Head>
  );
};



export default ArticleHead;
