import * as React from "react";

function SvgYoutube(props) {
  return (
    <svg
      id="youtube_svg__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 36 36"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".youtube_svg__st0{fill:#f7f5f3}"}</style>
      <path
        className="youtube_svg__st0"
        d="M18 0C8.1 0 0 8.1 0 18s8.1 18 18 18 18-8.1 18-18S27.9 0 18 0zm10 26H8V10h20v16z"
      />
      <path className="youtube_svg__st0" d="M15.9 14.2v6.9l5-3.4z" />
    </svg>
  );
}

export default SvgYoutube;
