import React from "react";
import Link from "./Link";
import Facebook from "../svgr/Facebook";
import Twitter from "../svgr/Twitter";
import Youtube from "../svgr/Youtube";

const SocialLink = ({type, data}) => {
  switch(type) {
    case "facebook":
      return <Link type="external" url={`https://www.facebook.com/${data}`} title={"View our facebook page"}>
        <Facebook />
      </Link>
    case "twitter":
      return <Link type="external" url={`https://twitter.com/${data}`} title={"View our twitter page"}>
        <Twitter />
      </Link>
    case "linkedin":
      return null;
    case "youtube":
      return <Link type="external" url={`https://youtube.com/${data}`} title={"View our youtube channel"}>
        <Youtube />
      </Link>
    default:
      return null
  }
};

export default SocialLink;
