import React, { useState } from "react";
import Caret from "../svgr/Caret";
import styled from "styled-components";
import { fonts, colors, breakpoints } from "../helpers";


  const Container = styled.div`
    border-bottom: 1px solid ${colors.blue};
    display: block;
    font-family: ${fonts["sans"]};
    font-weight: 300;
    position: relative;
    max-width: 950px;
    margin: 0 auto 2rem;

    span:last-of-type {
      padding: 0 0.5rem;
    }

    svg {
      transition: transform 0.25s;
    }

    &:before {
      background-color: ${colors.blue};
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transition: ease-in 0.25s;
      width: ${props => (props.isOpen ? "100%" : "10px")};
    }

    .question {
      align-items: center;
      color: ${props => (props.isOpen ? colors.softWhite : colors.blue)};
      display: flex;
      font-size: 18px;
      justify-content: space-between;
      line-height: 30px;
      padding: 0.5rem 0.25rem 0.5rem 1rem;
      position: relative;
      transition: all 0.25s;

      ${breakpoints.sm} {
        font-size: 24px;
        line-height: 36px;
        padding: 0.5rem 0.25rem 0.5rem 2rem;
      }

      svg {
        transform: ${props => (props.isOpen ? "rotate(-90deg)" : "rotate(0)")};
      }

      span {
        display: block;
      }
    }

    &:hover {
      svg {
        transform: ${props => (props.isOpen ? "rotate(-90deg)" : "rotate(90deg)")};
      }

      .question {
        cursor: pointer;
      }
    }
  `;

  const Answer = styled.div`
    background-color: ${colors.softWhite};
    color: ${colors.blue};
    font-size: 16px;
    left: 10px;
    line-height: 26px;
    padding: 1rem;
    position: relative;
    z-index: 1;

    ${breakpoints.sm} {
      font-size: 20px;
      padding: 2rem;
    }

    p {
      margin: 0;
    }
  `;


const AccordionItem = ({ question, answer }) => {
  const [showAnswer, setShowAnswer] = useState(false);
  const toggleAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <Container isOpen={showAnswer}>
      <div
        className={`question`}
        onClick={toggleAnswer}
      >
        <span>{question}</span>
        <span>
          <Caret />
        </span>
      </div>
      {showAnswer && (
        <Answer className="answer">
          <div dangerouslySetInnerHTML={{ __html: answer }} />
        </Answer>
      )}
    </Container>
  );
};

export default AccordionItem;
