import React from "react";
import styled from "styled-components";
import { line } from "../helpers";

const colors = {
  white: "#ffffff",
  softWhite: "#f7f5f3",
  blue: "#1e1e3a",
  red: "#F15829",
};

const Sect = styled.section`
  width: 100%;
  background-color: ${({ color }) =>
    color ? colors[color] : colors["softWhite"]};
  padding: 117px 0;
  position: relative;

  &:before,
  &:after {
    ${line}
  }

  &:before {
    top: 0;
    height: 40px;
  }

  &:after {
    bottom: 0;
    height: ${({ color }) => color === "white" ? "40px" : "0px" };
  }

  &:last-of-type:after {
    height: 0;
  }
`;


const Section = ({ children, color }) => <Sect color={color}>{children}</Sect>;

export default Section;
