import React from "react";
import styled from "styled-components";
import { fonts, colors, breakpoints, line } from "../helpers";

const NewsletterWrap = styled.div`
  background-color: ${colors.softWhite};
  color: ${colors.blue};
  font-family: ${fonts.sans};
  font-weight: 300;
  padding: 20px;

  h3 {
    font-weight: 300;
    font-size: 16px;
    margin-top: 0;
  }

  form {
    display: grid;
    grid-template-columns: 3fr 1fr;
    margin-top: 1rem;
    max-height: 60px;
    width: 100%;
    font-family: ${fonts.sans};
  }

  input, button {
    appearance: none;
    border: 1px solid ${colors.blue};
    min-height: 60px;
    outline: none;
    padding: 10px;
  }

  input[type="email"] {
    background-color: transparent;
    border-right: 0;
    color: ${colors.blue};
    font-family: ${fonts.sans};
    font-size: 12px;
  }

  input::placeholder {
    color: ${colors.blue};
  }

  button,
  input[type="submit"] {
    appearance: none;
    background-color: ${colors.blue};
    box-shadow: none;
    color: ${colors.white};
    font-family: ${fonts.sans};
    margin: 0;
    padding-top: 12px;
    transition: all 0.25s;

    &:hover {
      background-color: ${colors.red};
      border: 1px solid ${colors.red};
      cursor: pointer;
    }
  }

  ${breakpoints.md} {
    padding: 10px 40px;
    position: relative;

    form {
      margin-top: 0.75rem;
    }

    &:after {
      ${line}
      bottom: 0;
      top: 100px;
    }

    input[type="email"] {
      font-size: 18px;
    }

    h3 {
      font-size: 36px;
      line-height: 52px;
      text-align: right;
    }

    > div {
      display: grid;
      grid-gap: 100px;
      grid-template-columns: 1fr 1fr;
      margin: 70px auto;
      max-width: 820px;
    }
  }
`;

const NewsletterSignup = () => {
  return <NewsletterWrap>
    <div>
      <h3>Sign up for the Newsletter</h3>
      {
        // <!-- Begin Mailchimp Signup Form -->
      }
      <div id="mc_embed_signup">
      <form action="https://nyapsa.us10.list-manage.com/subscribe/post?u=760f0aa8605563e39cad64458&amp;id=73b42a4ab9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
      <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" placeholder="Email" />
      <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
      <div id="mce-responses" className="clear">
          <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
          <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
      </div>
      {
        // <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
      }
      <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_760f0aa8605563e39cad64458_73b42a4ab9" tabIndex="-1" /></div>
      </form>
      </div>
      <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
      {
        // <!--End mc_embed_signup-->
      }
    </div>
  </NewsletterWrap>;
};

export default NewsletterSignup;
