import React from "react";
import Moment from "react-moment";
import styled from "styled-components";
import { colors, breakpoints, fonts } from "../helpers";

const Title = styled.h1`
  font-family: ${fonts["sans"]};
  font-weight: 600;
  margin-top: 0;
  grid-area: title;
  padding: 1rem;

  ${breakpoints.sm} {
    font-size: 32px;
    line-height: 40px;
  }
`;
const Image = styled.figure`
  grid-area: image;
  margin: 0;
  padding: 0;
`;
const Head = styled.span`
  color: ${colors.blue};
  font-family: ${fonts["sans"]};
  grid-area: head;
  line-height: 40px;
  padding: 0 1rem;
  position: relative;
  transition: ease-in 0.3s;
  z-index: 2;

  ${breakpoints.sm} {
    font-size: 16px;
  }

  &:before {
    background-color: ${colors.blue};
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: ease-in 0.3s;
    width: 10px;
    z-index: -1;
  }
`;

const Tile = styled.article`
  width: 100%;
  /* padding: 20px; */
  border: 1px solid black;
  background-color: white;
  box-sizing: border-box;
  height: 100%;
  color: ${colors.blue};
  display: grid;
  grid-template-rows: auto 40px 1fr auto;
  grid-template-areas:
    "image image"
    "head head"
    "title title"
    "metaleft metaright";
  }
  &:hover{
    ${Head}{
      color: white;
      &:before{
        width: 100%;
      }
    }
  }
  ${breakpoints.sm} {
    min-height: 300px;
  }
`;

const metaStyles = `
  display: inline-block;
  border-top: 1px solid ${colors.blue};
  font-family: ${fonts["sans"]};
  font-weight: 300;

  ${breakpoints.sm} {
    font-size: 16px;
  }
`

const MetaLeft = styled.span`
  ${metaStyles}
  padding: 20px 10px 20px 20px;
`;
const MetaRight = styled.span`
  ${metaStyles}
  text-align: right;
  text-transform: uppercase;
  padding: 20px 20px 20px 10px;

  ${breakpoints.sm} {
    font-size: 16px;
  }
`;

const NewsTile = ({ title, image, head, metaLeft, metaRight, ...rest }) => {
  return (
    <Tile highlight={true}>
      <Image>{image}</Image>
      <Head>{head}</Head>
      <Title>{title}</Title>
      <MetaLeft>
        <Moment format="MMM D, YYYY">{metaLeft}</Moment>
      </MetaLeft>
      <MetaRight>{metaRight}</MetaRight>
    </Tile>
  );
};

export default NewsTile;
