import React, { useState, useEffect } from "react"
import styled, { withTheme } from "styled-components";
import { useTheme } from "../ThemeContext";
import { Link } from "react-router-dom";
import Container from "./Container";
import Section from "./Section";
import LoadMore from "./LoadMore";
import NewsTile from "./NewsTile";

import { fonts, breakpoints, colors } from "../helpers";
import { searchQuery } from "queries";
import fetch from "../fetch";

import Search from "./Search";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  a {
    width: 100;
    text-decoration: none;
  }

  ${breakpoints.sm} {
    grid-gap: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const FormWrap = styled.div`
  form {
    display: grid;
    grid-template-columns: 6fr 1fr;
    margin-top: 1rem;
    max-height: 60px;
    width: 100%;
    font-family: ${fonts.sans};
    margin-bottom: 40px;
  }

  input {
    appearance: none;
    border: 1px solid ${colors.blue};
    min-height: 60px;
    outline: none;
    padding: 10px;
  }

  input[type="text"] {
    background-color: transparent;
    border-right: 0;
    color: ${colors.blue};
    font-family: ${fonts.sans};
    font-size: 12px;
  }

  input[type="submit"] {
    appearance: none;
    background-color: ${colors.blue};
    border: 1px solid ${colors.blue};
    box-shadow: none;
    color: ${colors.white};
    font-family: ${fonts.sans};
    margin: 0;
    padding-top: 12px;
    transition: all 0.25s;

    &:hover {
      background-color: ${colors.red};
      border: 1px solid ${colors.red};
      cursor: pointer;
    }
  }
`;

const SearchResults = ({ match, theme }) => {
  const [query,setQuery] = useState();
  
  // const ref = useRef();
  // const updateQuery = useCallback(query => setQuery(query), [])
  
  // useEffect(() => {
  //     const timer = setTimeout(() => {
  //       updateQuery(query)
  //       },200)

  //       return () => {
  //           clearTimeout(timer)
  //         }
  //   },[query, updateQuery])
    
  const themeToggle = useTheme();

  // useEffect(() => {
  // },[query])

  useEffect(() => {
    if (theme.mode !== "dark") {
      themeToggle.toggle("dark");
    }
  });

  return <Section>
    <Container>
      <FormWrap>
        <Search setQuery={setQuery} />
      </FormWrap>
      <Grid>
        <Results query={query} />
      </Grid>
    </Container>
  </Section>
}

const LinkTile = ({entry, type}) => {
  return type === "resources" ? <NewsTile
    title={entry.title}
    head={entry.authoredBy}
    metaRight={entry.type[0] ? entry.type[0].title : null}
    metaLeft={entry.publicationDate}
  /> : <NewsTile
    title={entry.title}
    head={entry.publicationOutlet}
    metaRight={entry.type[0] ? entry.type[0].title : null}
    metaLeft={entry.publicationDate}
  />
}

const Results = ({query}) => {
  const { data } = fetch(searchQuery, { param: query });

  return data && data.entries && data.entries.length ? <LoadMore content={data.entries} copy={"See More"} limit={12}>
    {(data) => {
      return data.map(resource => {
        let resourceType = resource.type[0] ? resource.type[0].id : null
        return <div key={"resources-" + resource.id}>
          <Link to={`/${resource.uri}`}>
            <LinkTile entry={resource} type={resourceType} />
          </Link>
        </div>
      })
    }}
  </LoadMore> : <NoResults />
}

const NoResults = () => (
  <h2>No Results...</h2>
)

export default withTheme(SearchResults);
