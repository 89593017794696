import styled from "styled-components";

export const downloadEvent = label => {
  if (window.gtag) {
    window.gtag('event', 'download', {
      'event_category': 'resource download',
      'event_label': label
    })
  } else {
    console.log('no gtag!')
  }
}

export const colors = {
  white: "#ffffff",
  softWhite: "#f7f5f3",
  blue: "#1e1e3a",
  softBlue: "#617889",
  red: "#F15829",
};

export const fonts = {
  serif: "Heldane",
  sans: "acumin-pro, sans-serif"
};

export const size = {
  xs: "520px",
  sm: "768px",
  md: "1024px",
  lg: "1200px",
  xl: "1440px",
}

export const navHts = {
  mobileNavHeight: `60px`,
  deskNavHeight: `140px`,
  deskNavHeightSm: `80px`
}

export const breakpoints = {
  xs: `@media (max-width: ${size.xs})`,
  sm: `@media (min-width: ${size.sm})`,
  md: `@media (min-width: ${size.md})`,
  lg: `@media (min-width: ${size.lg})`,
  xl: `@media (min-width: ${size.xl})`,
};

export const Debug = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  display: none;
`;

export const line = `
  background-color: ${colors.red};
  content: '';
  left: 50%;
  pointer-events: none;
  position: absolute;
  width: 2px;
  z-index: 10;
`
