import React, { useRef } from "react"

const Search = ({
  setQuery
}) => {
  const ref = useRef()
  return <form onSubmit={event => {
    event.preventDefault();
    setQuery(ref.current.value);
  }}>
    <input 
      type="text" 
      name="search" 
      placeholder={"e.g. bail reform, pretrial support"}
      className={""}
      autoFocus
      ref={ref}
      />
    <input type="submit" value="Search" className="button" />
  </form>
}

export default Search