import React from "react";
import styled from "styled-components";

const Contained = styled.div`
  max-width: 1200px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
`;

const Container = ({ children }) => {
  return <Contained>{children}</Contained>;
};

export default Container;
